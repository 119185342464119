import { GlobalTimePeriodFilter } from '@va/dashboard/components';
import { useTranslate } from '@va/localization';
import MetricSelector from '@va/ui/components/metricSelector/MetricSelector';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { fontWeights, LineChart, Paragraph } from '@va/ui/design-system';
import { useAlarmingBehaviorEventsContext } from '../../../context/ABEContext';
import { metricsList, metricTranslations } from '../../../data/metrics';
import DataFetcherComponent from '../ABEGraphDataFetcher';
import { useABELineChart } from './useABELineChart';

export const AlarmingBehaviorEventsDetailsLineChart = ({
  noDataAvailableKey,
  titleKey,
  subtitleKey,
}: {
  noDataAvailableKey: string;
  titleKey: string;
  subtitleKey: string;
}) => {
  const translate = useTranslate();
  const { selectedAlarmingBehaviorEvents, selectedMetric, selectMetric, groupingKey } =
    useAlarmingBehaviorEventsContext();
  const { chartData, handleDataChange, isLoading, tooltipFn } = useABELineChart({ titleKey, subtitleKey });
  const { id } = useReportBlockCtx();

  return (
    <div className='w-full space-y-3'>
      {selectedAlarmingBehaviorEvents.map((selectedEvent, index) => {
        return (
          <DataFetcherComponent
            key={selectedEvent.trigger + index}
            selectedMetric={selectedMetric}
            onChange={handleDataChange}
            value={selectedEvent[groupingKey]}
            groupingKey={groupingKey}
            tooltip={{ value: selectedEvent.tooltipValue }}
          />
        );
      })}
      <div className='flex gap-3 flex-wrap'>
        <div className='flex-1'>
          <MetricSelector
            isIconVisible={false}
            translationsMap={metricTranslations}
            selectedMetric={selectedMetric}
            isDisabled={selectedAlarmingBehaviorEvents.length === 0}
            selectMetric={selectMetric}
            metricsList={metricsList}
          />
        </div>
        <div className='flex-1 flex items-center'>
          <GlobalTimePeriodFilter />
        </div>
      </div>
      {selectedAlarmingBehaviorEvents.length === 0 ? (
        <div className='h-500 flex items-center justify-center'>
          <Paragraph weight={fontWeights.medium}>{translate(noDataAvailableKey)}</Paragraph>
        </div>
      ) : (
        <LineChart
          tooltipFn={tooltipFn}
          isLoading={isLoading}
          className='w-full'
          wrapperStyle={{ height: '500px', overflow: 'hidden', width: '100%' }}
          id={`line-diagram-${id}`}
          chartData={chartData}
        />
      )}
    </div>
  );
};
