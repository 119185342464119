import { FILTERS_NAME } from '@va/constants';
import {
  useBrowserOptions,
  useCountryOptions,
  useDeviceOptions,
  useOssOptions,
  useVisitorTypeOptions,
} from '@va/dashboard/shared/filters';
import { useTranslate } from '@va/localization';
import { MultiSelectFilter, SingleSelectFilter, TextFilter } from '@va/shared/feature-filters';
import { Filter, FilterOperators } from '@va/types/filters';
import { useMemo } from 'react';

export const useLatestVisitorsFilterOptions = () => {
  const translate = useTranslate();

  const countryOptions = useCountryOptions();
  const deviceOptions = useDeviceOptions();
  const visitorTypeOptions = useVisitorTypeOptions();

  const browserNameFilter = useMemo(
    () =>
      new SingleSelectFilter({
        id: 'browserName',
        name: FILTERS_NAME.BROWSER,
        label: translate('filter.browserName'),
        inputProps: {
          showSearchInput: true,
          searchPlaceholder: translate('filters.placeholder.browserName'),
          useOptions: useBrowserOptions,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),
    [translate],
  );
  const osFilter = useMemo(
    () =>
      new SingleSelectFilter({
        id: 'operating-system',
        name: FILTERS_NAME.OPERATING_SYSTEM,
        label: translate('filter.operatingSystem'),
        inputProps: {
          searchPlaceholder: translate('filters.placeholder.platform'),
          showSearchInput: true,
          useOptions: useOssOptions,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),
    [translate],
  );

  const allFilterOptions: Filter[] = useMemo(() => {
    return [
      new TextFilter({
        id: '1',
        label: translate('filter.urlContaining'),
        appliedLabel: translate('filters.placeholder.urlAddress'),
        name: FILTERS_NAME.URL_CONTAIN,
        inputProps: { label: translate('filters.placeholder.urlAddress') },
        operator: FilterOperators.contains,
      }),
      new TextFilter({
        id: '2',
        label: translate('filter.urlNotContaining'),
        appliedLabel: translate('filters.placeholder.urlAddress'),
        name: FILTERS_NAME.URL_NOT_CONTAIN,
        inputProps: { label: translate('filters.placeholder.urlAddress') },
        operator: FilterOperators.doesNotContain,
      }),

      new SingleSelectFilter({
        id: 'countries',
        name: FILTERS_NAME.COUNTRY,
        label: translate('filter.countries'),
        inputProps: {
          useOptions: () => ({ data: countryOptions }),
          searchPlaceholder: translate('filters.placeholder.countries'),
          showSearchInput: true,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),

      new MultiSelectFilter({
        id: '4',
        label: translate('filter.devices'),
        name: FILTERS_NAME.DEVICE,
        inputProps: { useOptions: () => ({ data: deviceOptions }) },
      }),

      new MultiSelectFilter({
        id: '5',
        label: translate('filter.visitorType'),
        name: FILTERS_NAME.VISITOR_TYPE,
        inputProps: { useOptions: () => ({ data: visitorTypeOptions }) },
      }),

      new SingleSelectFilter({
        id: '6',
        label: translate('filter.onlySsrSessions'),
        name: FILTERS_NAME.ONLY_SSR_SESSIONS,
        inputProps: {
          useOptions: () => ({ data: [{ value: 'true', label: translate('filters.options.onlySsrSessions') }] }),
          showSearchInput: false,
        },
      }),

      new TextFilter({
        id: 'visitor-ip',
        label: translate('filter.visitorIp'),
        appliedLabel: translate('filters.placeholder.visitorIp'),
        name: FILTERS_NAME.VISITOR_IP,
        inputProps: {
          placeholder: translate('filters.placeholder.visitorIp'),
          label: translate('filters.placeholder.visitorIp'),
        },
        operator: FilterOperators.contains,
        valueMapper: (value, operator) => ({ [operator]: value }),
        operatorOptions: [FilterOperators.contains, FilterOperators.notContains],
      }),

      new TextFilter({
        id: 'company',
        label: translate('filter.company'),
        appliedLabel: translate('filters.placeholder.company'),
        name: FILTERS_NAME.COMPANY_NAME,
        inputProps: {
          placeholder: translate('filters.placeholder.company'),
          label: translate('filters.placeholder.company'),
        },
        operator: FilterOperators.contains,
        valueMapper: (value, operator) => ({ [operator]: value }),
        operatorOptions: [FilterOperators.contains, FilterOperators.notContains],
      }),

      osFilter,
      browserNameFilter,
    ];
  }, [browserNameFilter, countryOptions, deviceOptions, osFilter, translate, visitorTypeOptions]);

  return allFilterOptions;
};
