import { useCheckPrivacySetting } from '@va/dashboard/util-hooks';
import { useLocalizationContext } from '@va/localization';
import { SSRPlayer } from '@va/shared/ssr-player';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { MappedEventType } from '@va/types/recordings';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import { RecordingEvent, useGetSessionRecordings, useRecordingsEvents } from './apiClient';
import CustomPlayerControlsV2, { PlayerControlsProps } from './CustomPlayerControlsV2';
import useRecordingsDetailsContext from './recordingsDetailsContext';
import './RecordingsVideoPlayerV2.scss';

type RecordingsVideoPlayerV2Props = {
  size?: 'medium' | 'large';
  onFullScreen?: () => void;
  sessionId?: string | null;
  hideEventInProgressBar?: (event: RecordingEvent) => boolean;
  startFromEvent?: (event: MappedEventType) => boolean;
};

const RecordingsVideoPlayerV2 = ({
  size = 'large',
  onFullScreen,
  sessionId: sessionIdProp,
  hideEventInProgressBar,
  startFromEvent,
}: RecordingsVideoPlayerV2Props) => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const { translate, locale } = useLocalizationContext();

  const { data: recordings, isLoading: isLoadingRecordings } = useGetSessionRecordings(sessionIdProp ?? sessionId);

  const {
    events: parsedEvents,
    onParseEvents,
    playerRef,
    onCurrentEventChange,
    sessionInfo,
  } = useRecordingsDetailsContext();

  const { isTrackingEnabled } = useCheckPrivacySetting();

  const isPageHistoryTrackingEnabled = isTrackingEnabled(
    PRIVACY_FEATURES.individualPageHistory,
    sessionInfo?.countryCode as string,
  );

  const { events, isLoading: isLoadingEvents } = useRecordingsEvents(sessionIdProp ?? sessionId, recordings ?? []);

  const autoPlayStart = useMemo(() => {
    if (!startFromEvent || !parsedEvents) return;

    const event = parsedEvents.find((event) => startFromEvent(event));
    return event?.delay;
  }, [parsedEvents, startFromEvent]);

  const playerRecordings = useMemo(() => {
    return recordings?.filter((recording) => Object.keys(events ?? {}).includes(recording.id));
  }, [events, recordings]);

  const shouldRenderPlayer = useMemo(() => {
    if (isLoadingEvents || isLoadingRecordings || !recordings || !events || playerRecordings?.length === 0)
      return false;

    const recordingsIdsFromEvents = Object.keys(events);
    if (recordingsIdsFromEvents.length === 0) return false;

    return true;
  }, [events, isLoadingEvents, isLoadingRecordings, playerRecordings?.length, recordings]);

  return (
    <div className='ssr-player-container-v2 overflow-hidden grow'>
      {shouldRenderPlayer ? (
        <SSRPlayer
          // VISA-11298 - Force component re-mount when locale changes
          key={locale}
          ref={playerRef as any}
          recordings={playerRecordings}
          events={events}
          showEventsLog={false}
          autoPlay={true}
          autoPlayStart={autoPlayStart}
          hideEventInProgressBar={hideEventInProgressBar}
          controls={(props: PlayerControlsProps) => <CustomPlayerControlsV2 {...props} size={size} />}
          onCurrentEventChange={onCurrentEventChange}
          onEventsParsed={onParseEvents}
          getEventText={(eventName: string, extras: any) => {
            let translateKey = `ssrPlayer.events.${eventName}`;

            if (!isPageHistoryTrackingEnabled && eventName === 'meta') {
              translateKey = 'ssrPlayer.events.metaConsentless';
            }

            return translate(translateKey, extras);
          }}
          getEventTooltip={(eventName: string, extras: any) => {
            let translateKey = `ssrPlayer.events.tooltip.${eventName}`;

            if (!isPageHistoryTrackingEnabled && eventName === 'meta') {
              translateKey = 'ssrPlayer.events.tooltip.metaConsentless';
            }

            return translate(translateKey, extras);
          }}
          onFullScreen={onFullScreen}
        />
      ) : (
        <div className='aspect-video w-full'>
          <Skeleton className='h-full w-full rounded-18' />
        </div>
      )}
    </div>
  );
};

export default RecordingsVideoPlayerV2;
