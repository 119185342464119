export const metricsMap = {
  count: 'count',
  percentOfTotal: 'percentOfTotal',
};

export const metricTranslations = {
  [metricsMap.count]: 'all.eventTracking.alarmingBehaviorEvents.donutChart.metrics.numberOfDetections',
  [metricsMap.percentOfTotal]: 'all.eventTracking.alarmingBehaviorEvents.donutChart.metrics.percentOfTotalEvents',
};

export const metricsList = Object.values(metricsMap);

export const metricsDatasetLabel = {
  [metricsMap.count]: 'Alarming Events Count',
  [metricsMap.percentOfTotal]: 'Alarming Events Percent of Total',
};
