import { FILTERS_NAME } from '@va/constants';
import { useTranslate } from '@va/localization';
import { SingleSelectFilter } from '@va/shared/feature-filters';
import { useBooleanOptions } from './filter-options';

export const useWatchedFilter = () => {
  const translate = useTranslate();
  const booleanOptions = useBooleanOptions({ falseValue: 'false', trueValue: 'true' });
  return new SingleSelectFilter({
    id: 'watched',
    label: translate('filters.options.watched'),
    name: FILTERS_NAME.WATCHED,
    inputProps: { useOptions: () => ({ data: booleanOptions }), showSearchInput: false },
  });
};
