import { get } from '@va/http-client';

/**
 * @param  {string} resource
 * @return {requestFunction}
 */
const getRequest = (resource) => {
  /**
   * @function requestFunction
   *
   * @param  {string} websiteId
   * @param  {Object} filter
   * @param  {string} filter.from
   * @param  {string} filter.until
   * @return {Promise}
   */
  return function requestFunction(websiteId, filter) {
    const url = `/websites/${websiteId}/devices/${resource}`;
    return get(url, filter ? filter : {});
  };
};

export const screensizeStats = getRequest('screensizes');
export const browserStats = getRequest('browsers');
export const osStats = getRequest('os');
export const devicesStats = getRequest('device-types');
