import { createColumnHelper } from '@tanstack/react-table';
import { DetailsCell, DurationCell, ReferrerCell, VisitorDetailsCell } from '@va/dashboard/components';
import {
  ABECell,
  calculateTotalAlarmingEvents,
  IdAndDateCell,
  PagesCell,
  RecordingsOperationsButton,
  VisitorAlarmingActionCount,
} from '@va/dashboard/shared/recordings';
import { NegativeTagIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { RecordingType } from '@va/types/recordings';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { useMemo } from 'react';

const columnHelper = createColumnHelper<RecordingType>();

export const useRecordingsByAbeColumns = () => {
  const translate = useTranslate();

  return useMemo(
    () => [
      columnHelper.group({
        id: 'id',
        minSize: 200,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.recordingIdAndDate')}
            tooltip={translate('all.recordings.table.headerTooltips.idAndDate')}
          />
        ),
        cell: (props) => {
          const {
            id,
            sessionStart,
            sessionExtras: { star, seen },
            comment,
          } = props.row.original;
          return <IdAndDateCell note={comment?.text} recordingId={id} date={sessionStart} star={star} seen={seen} />;
        },
      }),
      columnHelper.accessor('ssrInfo.duration', {
        id: 'duration',
        minSize: 90,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.recordingDuration')}
            tooltip={translate('all.recordings.table.headerTooltips.recordingDuration')}
          />
        ),
        cell: (props) => <DurationCell duration={props.getValue()} />,
      }),
      columnHelper.group({
        minSize: 80,
        id: 'visitorActions',
        header: (ctx) => {
          const data = ctx.table.getRowModel().rows.map((row) => row.original);
          const totalAlarmingEvents = calculateTotalAlarmingEvents(data);
          return (
            <HeaderCellV8
              context={ctx}
              text={translate('tableHeaders.visitorActions')}
              tooltip={translate('all.recordings.table.headerTooltips.visitorActions')}
              additionalInfo={<VisitorAlarmingActionCount count={totalAlarmingEvents} />}
            />
          );
        },
        cell: (props) => {
          const { alarmingEvents } = props.row.original;
          return <ABECell alarmingEvents={alarmingEvents} />;
        },
      }),
      columnHelper.accessor('ssrInfo.pagesCount', {
        minSize: 190,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.pages')}
            tooltip={translate('all.recordings.table.headerTooltips.pages')}
          />
        ),
        cell: (props) => {
          const {
            entryPage,
            exitPage,
            ssrInfo: { pagesCount, pages },
            countryCode,
            id,
          } = props.row.original;

          return (
            <PagesCell
              sessionId={id}
              entryPage={entryPage}
              exitPage={exitPage}
              pages={pages}
              totalCount={pagesCount}
              countryCode={countryCode}
              isClickable
            />
          );
        },
      }),
      columnHelper.group({
        id: 'visitorDetails',
        minSize: 140,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.visitorDetails')}
            tooltip={translate('all.recordings.table.headerTooltips.visitorDetails')}
          />
        ),
        cell: (props) => {
          const { status, countryCode, location } = props.row.original;
          return <VisitorDetailsCell location={location} visitorType={status} countryCode={countryCode} isClickable />;
        },
      }),
      columnHelper.accessor('referrer', {
        minSize: 160,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.referrer')}
            tooltip={translate('all.recordings.table.headerTooltips.referrer')}
          />
        ),
        cell: (props) => <ReferrerCell referrer={props.getValue()} />,
      }),
      columnHelper.group({
        id: 'details',
        minSize: 160,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.recordingDetails')}
            tooltip={translate('all.recordings.table.headerTooltips.recordingDetails')}
          />
        ),
        cell: (props) => {
          const { browser, platform, device } = props.row.original;
          return <DetailsCell browser={browser} platform={platform} device={device} />;
        },
      }),
      columnHelper.display({
        id: 'actions',
        minSize: 100,
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.actions')}
            tooltip={translate('all.recordings.table.headerTooltips.actions')}
          />
        ),
        cell: (props) => {
          const {
            id,
            comment,
            sessionExtras: { star },
          } = props.row.original;

          return <RecordingsOperationsButton hasComment={!!comment} recordingId={id} star={star} />;
        },
      }),
    ],
    [translate],
  );
};
