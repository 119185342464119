import {
  bannerName,
  cardView,
  defaultStepFilter,
  defaultTimezone,
  defaultTrafficFilter,
  MAX_MOBILE_VIEW_WIDTH,
  MAX_MOBILE_XS_WIDTH,
  MAX_PANEL_MOBILE_WIDTH,
  MOBILE_BREAKPOINT,
  panelsPaginationTabNames,
  reportBlockPaths,
  TABLET_BREAKPOINT,
  tabNames,
  UNTRANSLATED_LOCALE,
} from '@va/constants';
import { getFilterDateWithTimezone } from '@va/util/helpers';
import Immutable from 'immutable';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

export * from './filters';
export * from './funnels';
export * from './heatmaps';
export * from './navigation';
export * from './tooltips';

/** @deprecated */
export const getLocale = (state) => {
  const locale = state.getIn(['core', 'ui', 'locale'], 'en');
  return locale === UNTRANSLATED_LOCALE ? 'en' : locale;
};

export const getNotification = (state) => state.getIn(['core', 'ui', 'notification'], null);

export const needToClearNotifications = (state) => state.getIn(['core', 'ui', 'clearNotifications'], false);

export const isHorizontalBarChartAllDataShown = (state, chartId) =>
  state.getIn(['core', 'ui', 'horizontalBarChart', 'showAllData', chartId ? chartId : 'default'], false);

/**
 * @type {(state) => {from: moment.Moment, until: moment.Moment, unit: string, start?: number, length?: number, search?: string}}
 */
export const getGlobalFilter = createSelector(
  [
    (state) => state.getIn(['core', 'ui', 'globalFilter'], getDefaultFilter(state)),
    (state) => {
      const predefinedFilters = state.get('app').predefinedFilters;
      if (predefinedFilters?.timezone) return predefinedFilters.timezone;

      return state.getIn(['core', 'website', 'timezone'], defaultTimezone);
    },
    (state) => state.getIn(['core', 'ui', 'activeTab'], null),
    (state) => state,
  ],
  (globalFilter, timezone, activeTab, state) => {
    globalFilter.from = getFilterDateWithTimezone(globalFilter.from, timezone);
    globalFilter.until = getFilterDateWithTimezone(globalFilter.until, timezone);

    if (panelsPaginationTabNames.includes(activeTab)) {
      const range = state.getIn(['core', 'ui', 'panelsPagination', activeTab], null);
      globalFilter.start = (range && range.get('start')) || 0;
      globalFilter.length = (range && range.get('length')) || 25;
    }
    globalFilter.unit = globalFilter.unit || 'day';
    globalFilter.search = globalFilter.search || '';
    return globalFilter;
  },
);

export const getDefaultDateRange = createSelector(
  [(state) => state.getIn(['core', 'website', 'timezone'], defaultTimezone)],
  (timezone, from, until) => {
    const fromValue = from ?? moment().tz(timezone).startOf('day').subtract(29, 'day');
    const untilValue = until ?? moment().tz(timezone).endOf('day');
    return [fromValue, untilValue];
  },
);

export const getDefaultFilter = createSelector(
  [
    (state) => parseInt(state.getIn(['core', 'website', 'installDate'])),
    (state) => state.getIn(['core', 'website', 'timezone'], defaultTimezone),
    (state) => getDefaultDateRange(state),
  ],
  (installDate, timezone, defaultDate) => {
    const [aMonthAgo, until] = defaultDate;
    // start from install date or one month ago
    const from =
      aMonthAgo.unix() < installDate
        ? moment(installDate * 1000)
            .tz(timezone)
            .startOf('day')
        : aMonthAgo.clone();
    return { from, until };
  },
);

export const getPreviousGlobalFilter = (state) => {
  const filter = getGlobalFilter(state);

  const durationInDays = filter.until.diff(filter.from, 'days') + 1;
  const previousPeriodStart = filter.from.clone().tz(window.timezone).subtract(durationInDays, 'days');
  const previousPeriodEnd = filter.from.clone().tz(window.timezone).subtract(1, 'seconds');

  return {
    from: previousPeriodStart,
    until: previousPeriodEnd,
  };
};

export const getPreviousGlobalFilterV2 = createSelector([getGlobalFilter, (state) => state], (globalFilter, state) => {
  return state.getIn(['core', 'ui', 'previousGlobalFilter'], globalFilter);
});

export const getPreviousYearGlobalFilter = (state) => {
  const filter = getGlobalFilter(state);
  const previousPeriodStart = filter.from.clone().tz(window.timezone).subtract(1, 'year');
  const previousPeriodEnd = filter.until.clone().tz(window.timezone).subtract(1, 'year');
  return {
    from: previousPeriodStart,
    until: previousPeriodEnd,
  };
};

export const getTrafficFilter = (state) => state.getIn(['core', 'ui', 'trafficFilter'], defaultTrafficFilter);

export const getStepFilter = (state) => state.getIn(['core', 'ui', 'stepFilter'], defaultStepFilter);

export const getTabProperty = (state, tabName, propertyName, defaultValue) => {
  return state.getIn(['core', 'ui', tabName, propertyName], defaultValue);
};

export const getActiveTab = (state) => state.getIn(['core', 'ui', 'activeTab'], null);
export const getPreviousActiveTab = (state) => state.getIn(['core', 'ui', 'previousActiveTab'], null);

export const getAppHistoryLength = (state) => state.getIn(['core', 'ui', 'appHistoryLength'], 1);
export const getAppEntryPath = (state) => state.getIn(['core', 'ui', 'appEntryPath'], null);

export const getSettings = (state) => state.getIn(['core', 'ui', 'settings'], Immutable.Map()).toJS();

export const getReferringSitesViewMode = (state) => state.getIn(['core', 'ui', 'referringSites', 'viewMode'], 'view');

export const getSelectedReferrerUrl = (state) =>
  state.getIn(['core', 'ui', 'referringSites', 'selectedReferrerUrl'], '#');

export const shouldSaveTimezoneSettings = (state) =>
  state.getIn(['core', 'ui', 'settings', 'locationCard', 'shouldSave'], false);

export const getSelectedVisitorId = (state) =>
  state.getIn(['core', 'ui', tabNames.visitorsHistory, 'selectedVisitorId'], null);

export const isDatePickerVisible = (state) => state.getIn(['core', 'ui', 'filter', 'isDatePickerVisible'], false);
export const isFilterExpanded = (state) => state.getIn(['core', 'ui', 'filter', 'isExpanded'], false);

export const isTranslationSucceeded = (state) => state.getIn(['core', 'ui', 'translations', 'succeeded'], false);

export const getWindowWidth = (state) => state.getIn(['core', 'ui', 'windowWidth'], 1024);

/**
 * @deprecated
 * @use isMobileDevice instead
 *
 */
export const isMobileSm = (state) => getWindowWidth(state) <= MAX_MOBILE_XS_WIDTH;
/**
 * @deprecated
 * @use isMobileDevice instead
 *
 */
export const isMobile = (state) => getWindowWidth(state) <= MAX_MOBILE_VIEW_WIDTH;
/**
 * @deprecated
 * Switching to tablet mode from 1024px
 */
export const isTablet = (state) => getWindowWidth(state) <= MAX_PANEL_MOBILE_WIDTH;
export const isTabletDevice = (state) => getWindowWidth(state) < TABLET_BREAKPOINT;
export const isMobileDevice = (state) => getWindowWidth(state) < MOBILE_BREAKPOINT;
export const isSmallDevice = (state) => getWindowWidth(state) < 378;
export const isMediumDevice = (state) => getWindowWidth(state) < 768;

export const hasAgreedConsentInSession = (state, consentFlag) =>
  state.getIn(['core', 'ui', 'consent', consentFlag], false);

export const getLocationCardTimezone = (state) =>
  state.getIn(['core', 'ui', 'settings', 'timezone'], state.getIn(['core', 'website', 'timezone'], defaultTimezone));

export const isModalOpen = (state, modalName) => state.getIn(['core', 'ui', 'modals', modalName, 'open'], false);
export const isModalMounted = (state) => state.getIn(['core', 'ui', 'modals', 'mounted'], false);
export const getModalExtraProps = (state, modalName) =>
  state.getIn(['core', 'ui', 'modals', modalName, 'extraProps'], null);
export const getCheckLoginModal = (state) =>
  state.getIn(['core', 'ui', 'modals', 'checkLogin'], Immutable.Map()).toJS();
export const getCardView = (state, cardName) => state.getIn(['core', 'ui', cardName, 'view'], cardView.INITIAL);
export const getPreviousCardView = (state, cardName) => state.getIn(['core', 'ui', cardName, 'previousView'], null);

export const getUpgradeRequestId = (state) => state.getIn(['core', 'ui', 'upgradeRequest'], null);

export const shouldAutoExpandAnswer = (state) =>
  state.getIn(['core', 'ui', 'supportFaq', 'shouldAutoExpandAnswer'], false);
export const getAutoExpandAnswerId = (state) => state.getIn(['core', 'ui', 'supportFaq', 'autoExpandAnswerId'], null);

export const showPasswordWrongMessage = (state) =>
  state.getIn(['core', 'ui', 'modals', 'checkLogin', 'showPasswordWrongMessage'], false);

export const isAdditionalFiltersExpanded = (state) => {
  const activeTab = getActiveTab(state);
  return state.getIn(['core', 'ui', 'recordingsPanel', 'filtersMenu', 'expanded', activeTab], false);
};

export const getAdditionalFilters = (state, tabName) => {
  const activeTab = tabName ? tabName : getActiveTab(state);
  const filterTab = currentFiltersTab(activeTab);
  return state.getIn(['core', 'ui', 'recordingsPanel', 'filtersMenu', filterTab]);
};

export const isAdditionalFilterOptionsOpen = (state, categoryType, tabName) => {
  const filterTab = tabName === tabNames.recordings ? 'recordingFilterOptionsOpen' : 'latestVisitorsFiltersOptionOpen';
  return state.getIn(['core', 'ui', 'recordingsPanel', 'filtersMenu', filterTab, categoryType], false);
};

export const getAdditionalFilterValue = (state, option) => {
  const activeTab = getActiveTab(state);
  const filterTab = currentFiltersTab(activeTab);
  const filters = state.getIn(['core', 'ui', 'recordingsPanel', 'filtersMenu', filterTab]);
  return filters && filters.length > 0 && filters.find((item) => item.name === option).value;
};

export const getAnnouncementData = (state) => state.getIn(['core', 'ui', 'modals', 'announcement', 'data'], []);
export const isAnnouncementViewedInSession = (state) =>
  state.getIn(['core', 'ui', 'modals', 'announcement', 'viewedInSession'], false);

export const getDevicePageBarChartId = (state) => state.getIn(['core', 'ui', 'devicePageBarChartId'], null);
export const getSelectedFunnelId = (state) => state.getIn(['core', 'ui', 'funnels', 'selectedFunnelId'], null);

function currentFiltersTab(tabName) {
  return tabName === tabNames.recordings ? 'recordingFilters' : 'latestVisitorsFilters';
}

export const isStepFilterWithPreviousSelected = (state) => state.getIn(['core', 'ui', 'stepFilterWithPrevious'], false);

export const getTooltipTheme = (state) => state.getIn(['core', 'ui', 'tooltipTheme']);

export const shouldRefreshDatePicker = (state) => state.getIn(['core', 'ui', 'filter', 'shouldRefreshDatePicker']);

export const getNotificationV2 = (state) => state.getIn(['core', 'ui', 'notifications']).toJS();

export const isWelcomePage = (state) => state.getIn(['core', 'ui', 'isWelcomePage'], false);

/**
 * @deprecated
 * Not needed in the context of NX
 */
export const showTranslationsLoader = (state) => {
  const { inProgress, succeeded, isFirstLoad } = state.getIn(['core', 'ui', 'translations']).toJS();
  return inProgress && !succeeded && isFirstLoad;
};

export const isReportBlockPage = (state) => {
  //check if ui is in onboarding
  if (isWelcomePage(state)) {
    return false;
  }

  //check if current path matches the report block paths
  const currentPathName = window.location.pathname;
  const pathsToInclude = reportBlockPaths.includes;
  const pathsToEndWith = reportBlockPaths.endsWith;

  const includesMatch = pathsToInclude.some((path) => currentPathName.includes(path));
  const endsWithMatch = pathsToEndWith.some((path) => new RegExp(`^.*${path}$`).test(currentPathName));

  return includesMatch || endsWithMatch;
};

export const isLimitReachedBannerVisible = (state) =>
  state.getIn(['core', 'ui', 'banners', bannerName.LIMIT_REACHED, 'open']);
