import { UNTRANSLATED_LOCALE } from './dashboard';

export const PAGE_CONTENT_STICKY_ITEMS_WRAPPER_ID = 'page-content-sticky-items-wrapper';
export const PAGE_CONTENT_TABLE_HEADER_WRAPPER_ID = 'page-content-table-header-wrapper';

export const LAYOUT_PORTAL_ID = 'layout-portal-wrapper';
export const PAGE_HEADER_RESIZE_EVENT = 'page-header-resize';

export const MANUALLY_TRANSLATED_LANGUAGES = ['ro', 'de', 'en', UNTRANSLATED_LOCALE];

export const PAGE_HEADER_OTHER_ITEMS_WRAPPER_ID = 'page-header-other-items-wrapper';
export const PAGE_HEADER_NESTED_TABS_WRAPPER_ID = 'page-header-nested-tabs-wrapper';
export const ACCOUNT_SETTINGS_NOTIFICATIONS_ID = 'notifications-settings';

export const bannerName = {
  LIMIT_REACHED: 'limitReached',
};
