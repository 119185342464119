import { allowedPlatformsForWebsiteCreation, platform } from '@va/constants';
import { getParameterByName, SessionStorage } from '@va/util/helpers';
import { useEffect } from 'react';

const platformValueStorageKey = 'platform-value';

function isPlatformAllowed(allowedPlatformName: string, platformFromQuery: string) {
  return allowedPlatformName.toLowerCase() === platformFromQuery.toLowerCase();
}

export function usePlatformQueryParam() {
  useEffect(() => {
    const platform = getParameterByName('platform');
    if (
      platform &&
      Object.values(allowedPlatformsForWebsiteCreation).some((allowedPlatformName) =>
        isPlatformAllowed(allowedPlatformName, platform),
      )
    ) {
      SessionStorage.setItem(platformValueStorageKey, platform.toUpperCase());
    }
  }, []);

  const getPlatformFromStorage = () => {
    return SessionStorage.getItem(platformValueStorageKey) ?? platform.CUSTOM;
  };

  return { getPlatformFromStorage };
}
