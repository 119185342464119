import { fetchAlarmingBehaviorEventsGraph } from '@va/dashboard/api-client/event-tracking.api';
import { useCurrentPeriodFilter, useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { useLocale, useLocalizationContext } from '@va/localization';
import { AlarmingBehaviorEventsGraphFetchParams, Group } from '@va/types/events';
import { BaseTimeseriesResponse, RawTimeseriesResponse } from '@va/types/time-series';
import { addNumberSeparator, chartHelpers } from '@va/util/helpers';
import { useFetchData } from '@va/util/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { graphType } from '../../data/constants';
import { metricsMap } from '../../data/metrics';

export default function DataFetcherComponent({
  value,
  onChange,
  selectedMetric,
  groupingKey,
  tooltip: { value: tooltipValue, getLinkClickHandler, getLinkToValue },
}: {
  value: string;
  onChange: (data: BaseTimeseriesResponse<string> | undefined, isLoading: boolean, selectedEvent: string) => void;
  selectedMetric: string;
  groupingKey: Group;
  tooltip: {
    value: string;
    getLinkClickHandler?: (key: string) => () => void;
    getLinkToValue?: (key: string) => string;
  };
}) {
  const { from, unit, until, websiteId } = useCurrentPeriodFilter();
  const { locale, translate } = useLocalizationContext();

  const query = useMemo(() => ({ from, until, type: graphType, unit }), [from, until, unit]);

  const fetcher = useCallback(async (params: AlarmingBehaviorEventsGraphFetchParams) => {
    return fetchAlarmingBehaviorEventsGraph(params);
  }, []);

  const mapper = useCallback(
    (res: RawTimeseriesResponse): BaseTimeseriesResponse<string> => ({
      ...chartHelpers.buildBaseTimeseriesResponse(
        [
          {
            ...res.payload,
            datasets: res.payload.datasets.map((dataset) => ({
              ...dataset,
              data:
                selectedMetric === metricsMap.percentOfTotal
                  ? dataset.data.map((data) => Number(addNumberSeparator(data * 100, locale)))
                  : dataset.data,
            })),
          },
        ],
        {
          extractDatasetKey: (dataset) => dataset.label,
          getDatasetLabel: () => tooltipValue,
          getLinkToValue,
          getLinkClickHandler,
          getLinkLabel: () => translate('tooltip.abe.showRecordingWithEvent'),
          until: until.toString(),
          unit: unit ?? 'day',
        },
      ),
    }),
    [getLinkToValue, getLinkClickHandler, until, unit, selectedMetric, locale, tooltipValue],
  );

  const body = useMemo(
    () => ({
      where: { and: [{ member: groupingKey, operator: 'in', values: [value] }] },
    }),
    [groupingKey, value],
  );

  const { data, isLoading, mutate } = useFetchData(
    [{ websiteId, query, body }, selectedMetric],
    { revalidateIfStale: false, revalidateOnFocus: false },
    mapper,
    fetcher,
  );

  const { isManualRefreshing } = useMutateOnRefresh(mutate);

  useEffect(() => {
    onChange(data, isLoading || isManualRefreshing, value);
  }, [data, isLoading, isManualRefreshing, onChange, value]);

  return null;
}
