import { get } from '@va/http-client';
import { RequestCanceler, useCancelOnUnmount } from '@va/util/misc';
import { useCallback, useMemo } from 'react';
import useSWR, { Key, SWRConfiguration, SWRResponse as SWRResponseInterface } from 'swr';

declare module 'swr' {
  export interface SWRResponse {
    isLoading: boolean;
  }
}

export type SWRResponse<T, D> = SWRResponseInterface<T, D> & {
  isLoading: boolean;
};

export type useFetchDataParams<T, D = Error> = {
  key: Key;
  config: SWRConfiguration<T, D> & { lazyLoaded?: boolean };
  mapper?: (data: any) => T;
  fetchFunc?: (...args: any[]) => Promise<unknown>;
};

export function useFetchData<T, D = Error>(
  key: useFetchDataParams<T, D>['key'],
  config?: useFetchDataParams<T, D>['config'],
  mapper?: useFetchDataParams<T, D>['mapper'],
  fetchFunc?: useFetchDataParams<T, D>['fetchFunc'],
): SWRResponse<T, D> {
  const requestId = useMemo(() => RequestCanceler.generateId(), []);
  useCancelOnUnmount(requestId);

  const fetcher = useCallback(
    async (...args: any[]) => {
      if (fetchFunc) {
        const response = await fetchFunc(...args);
        return (mapper ? mapper(response) : response) as Promise<T>;
      }

      const signal = RequestCanceler.onRequestStart(requestId);
      const response = await get(args[0], {}, { signal });
      RequestCanceler.onRequestEnd(requestId);
      return (mapper ? mapper(response) : response) as Promise<T>;
    },
    [fetchFunc, mapper, requestId],
  );

  const onErrorFunc = useMemo(() => {
    if (config?.onError) return config.onError;

    return () => {
      RequestCanceler.onRequestEnd(requestId);
    };
  }, [config?.onError, requestId]);

  const swr = useSWR<T, D>(key, fetcher, {
    ...config,
    onError: onErrorFunc,
  });

  return {
    ...swr,
    // isLoading has to do with the initial fetch request
    // isValidating will be set to true any time there is any refetching, on the initial request and on all the others requests
    isLoading: isSWRLoading<T, D>(swr, config, key),
    error: getSwrError(swr),
  };
}

export const USER_ABORTED_REQUEST_MSG = 'The user aborted a request.';
const SIGNAL_ABORTED_NO_REASON = 'signal is aborted without reason';

const isReqAbortedError = (error: Error | string) => {
  let msg = '';

  if (typeof error === 'string') {
    msg = error;
  } else if (typeof error === 'object') {
    msg = error?.message;
  }

  return (
    msg.includes(USER_ABORTED_REQUEST_MSG) ||
    // fix for requests being cancelled
    msg.includes(SIGNAL_ABORTED_NO_REASON)
  );
};

export const getSwrError = (swr: SWRResponseInterface) => {
  return isReqAbortedError(swr.error as Error) ? undefined : swr.error;
};

export const isSWRLoading = <T, D>(swr: SWRResponseInterface, cfg?: useFetchDataParams<T, D>['config'], key?: Key) => {
  const { error } = swr;

  let msg = '';

  if (typeof error === 'string') {
    msg = error;
  } else if (typeof error === 'object') {
    msg = error?.message;
  }

  if (msg.includes(USER_ABORTED_REQUEST_MSG) || msg.includes(SIGNAL_ABORTED_NO_REASON)) {
    msg = '';
  }

  // If the request is not lazy loaded, the isLoading flag must be set to false from the start
  if (cfg?.lazyLoaded === false && key === null) {
    return false;
  }

  // UI/UX fix for lazy loading, intersection observer needs 1 additional rendering cycle to trigger data fetching,
  // in this case swr.isLoading & swr.isValidating will be initially set to false for a split second, causing flickering in the UI
  if (cfg?.revalidateOnMount !== false && !swr.isLoading && swr.data === undefined && msg === '' && !swr.isValidating) {
    return true;
  }

  const isLoading = swr.isValidating ? !msg && swr.data === undefined : false;

  return isLoading;
};
