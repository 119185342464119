import { getInstanceId } from '@va/dashboard/selectors/app';
import { get } from '@va/http-client';
import { DeviceTypesEnum } from '@va/types/device';
import { AlarmingEvents, AlarmingEventTrigger, EventsData as EventsCountData } from '@va/types/events';
import { LocationType } from '@va/types/location';
import { AlarmingEvent, AlarmingEventType, RecordingCommentType } from '@va/types/recordings';
import { VisitorTypes } from '@va/types/visitors';
import { useFetchData } from '@va/util/hooks';
import { EventType } from '@visa/rrweb';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type SessionInfoType = {
  alarmingEvents: AlarmingEvents;
  events: EventsCountData;
  browserName: string;
  countryCode: string;
  deviceType: DeviceTypesEnum;
  ip: string;
  location: LocationType;
  pagesVisited: number;
  platform: string;
  referrer: string;
  screenSize: string;
  sessionKey: string;
  timestamp: number;
  visitorKey: string;
  status: VisitorTypes;
  comment: RecordingCommentType | null;
  sessionExtras: {
    seen: boolean;
    star: boolean;
    id: string;
  };
};

type RecordingType = {
  compressed: boolean;
  compression: string;
  createdAt: string;
  duration: number;
  id: string;
  metadata: {
    events: number;
    clickInteractions: number;
    incSnapshots: number;
    scroolInteractions: number;
  };
  page: number;
  pending: boolean;
  scopes: string[];
  sessionId: string;
  status: string;
  websiteId: string;
  wsSessionId: string;
};

export type RecordingEvent = {
  createdAt: string | null;
  data: RecordingEventData;
  delay?: number;
  id: string;
  timestamp: number;
  type: EventType;
  recordingId: string;
};

type RecordingEventData = {
  source?: AlarmingEventTrigger | number;
  [key: string]: any;
};

type EventsData = { [recordingId: string]: RecordingEvent[] | MappedAlarmingEvent[] };

type AlarmingEventsResponse = {
  meta: {
    total: number;
  };
  payload: Array<AlarmingEvent>;
};

export type MappedAlarmingEvent = {
  timestamp: number;
  trigger: AlarmingEventTrigger;
  type: AlarmingEventType;
  recordingId?: string;
};

export function convertAbeToRecordingEvent(abe: MappedAlarmingEvent): RecordingEvent {
  return {
    createdAt: new Date(abe.timestamp).toISOString(),
    data: {
      type: abe.type,
      source: abe.trigger,
    },
    id: `${abe.trigger}-${abe.type}-${abe.timestamp}`,
    recordingId: abe?.recordingId ?? '',
    timestamp: abe.timestamp,
    type: EventType.Custom,
  };
}

export function splitAbe(abe: AlarmingEvent): MappedAlarmingEvent[] {
  if (
    abe.trigger === AlarmingEventTrigger.uTurns ||
    abe.trigger === AlarmingEventTrigger.refreshes ||
    abe.trigger === AlarmingEventTrigger.deadClicks
  ) {
    return [
      {
        timestamp: abe.end,
        trigger: abe.trigger,
        type: AlarmingEventType.ABE_ONE_TIME,
        recordingId: abe.recordingId,
      },
    ];
  }
  return [
    {
      timestamp: abe.start,
      trigger: abe.trigger,
      type: AlarmingEventType.ABE_START,
      recordingId: abe.recordingId,
    },
    {
      timestamp: abe.end,
      trigger: abe.trigger,
      type: AlarmingEventType.ABE_END,
      recordingId: abe.recordingId,
    },
  ];
}

export const useGetSessionABEs = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);

  const mapper = useCallback((abeResponse: AlarmingEventsResponse) => {
    return {
      ...abeResponse,
      payload: abeResponse.payload.map((abe) => ({ ...abe, start: abe.start * 1000, end: abe.end * 1000 })),
    };
  }, []);

  return useFetchData(
    `/v2/websites/${websiteId}/sessions/${sessionId}/alarming-events`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapper,
  );
};

export const useGetSessionRecordings = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);

  const mapper = useCallback((recordings: RecordingType[]) => {
    return padRecordings(recordings.map(transformRecording).sort((a, b) => a.start - b.start));
  }, []);

  return useFetchData<TransformedRecording[]>(
    `/websites/${websiteId}/sessions/${sessionId}/recordings`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapper,
  );
};

function addRecordingIdsToAbes(recordings: TransformedRecording[], initialAbes: AlarmingEvent[]) {
  return initialAbes.flatMap(splitAbe).map((abe) => {
    if (abe.recordingId !== '') return abe;
    const associatedRecording = recordings.find((r) => abe.timestamp >= r.start && abe.timestamp <= r.end);
    if (associatedRecording) {
      abe.recordingId = associatedRecording.id;
    }
    return abe;
  });
}

export const useRecordingsEvents = (sessionId: string, recordings: TransformedRecording[]) => {
  const [events, setEvents] = useState<EventsData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { data: abes, isLoading: abesLoading } = useGetSessionABEs(sessionId);

  const websiteId = useSelector(getInstanceId);

  const fetchEvents = useCallback(
    async (recordingId: string, abes: MappedAlarmingEvent[]) => {
      const events = (await get(
        `/websites/${websiteId}/sessions/${sessionId}/recordings/${recordingId}`,
      )) as (RecordingEvent & { data: string })[];

      return {
        [recordingId]: events
          .map((event) => ({ ...event, data: JSON.parse(event.data) }))
          .concat(abes.map(convertAbeToRecordingEvent))
          .sort((a, b) => a.timestamp - b.timestamp),
      };
    },
    [sessionId, websiteId],
  );

  useEffect(() => {
    if (recordings.length === 0 || abesLoading) return;

    const abesWithRecordingIds = addRecordingIdsToAbes(recordings as TransformedRecording[], abes?.payload || []);

    const promises = recordings.map((recording) => {
      const abesForRecording = abesWithRecordingIds.filter((abe) => abe.recordingId === recording.id);
      if (recording.id.includes('padded')) {
        return Promise.resolve({ [recording.id]: abesForRecording.map(convertAbeToRecordingEvent) });
      }
      return fetchEvents(recording.id, abesForRecording);
    });
    setIsLoading(true);
    Promise.all(promises)
      .then((data) => {
        setEvents(() => {
          return data
            .filter((item) => Object.values(item)[0]?.length !== 0)
            .reduce((acc, item) => {
              return {
                ...acc,
                ...item,
              };
            }, undefined as EventsData | undefined);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [abes, abesLoading, fetchEvents, recordings]);

  return { events, isLoading };
};

export const useGetSessionInfo = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);
  return useFetchData<SessionInfoType>(`/websites/${websiteId}/sessions/${sessionId}/info`, {
    revalidateOnMount: true,
    revalidateIfStale: true,
  });
};

type TransformedRecording = Partial<RecordingType> & {
  id: string;
  start: number;
  end: number;
};

function transformRecording(recording: RecordingType): TransformedRecording {
  const start = new Date(recording.createdAt).getTime();
  return {
    ...recording,
    id: recording.id,
    start,
    end: start + recording.duration,
  };
}

function generatePadRecordingId(index: number) {
  return `paddedRecording-${index}`;
}

/**
 * Adds "padding recordings" to allow mapping ABEs that are not generated based on recording events
 * Padding recording is added at the beginning and between every two regular recordings
 * No padding recording is required at the end because the session ends when the last recording ends
 * so there can be no ABEs after that
 */
function padRecordings(recordings: Array<TransformedRecording>) {
  if (recordings.length === 0) return [];
  const firstRecordingStart = recordings[0].start;
  const timelineStart = firstRecordingStart - 5000;
  const paddedRecordings: Array<TransformedRecording> = [];

  paddedRecordings.push({
    id: generatePadRecordingId(0),
    start: timelineStart,
    end: firstRecordingStart,
    createdAt: new Date(timelineStart).toISOString(),
    duration: firstRecordingStart - timelineStart,
  });

  for (let i = 0; i < recordings.length; i++) {
    const current = recordings[i];
    paddedRecordings.push(current);

    if (i < recordings.length - 1) {
      const next = recordings[i + 1];

      if (current.end < next.start) {
        paddedRecordings.push({
          id: generatePadRecordingId(i + 1),
          start: current.end,
          end: next.start,
          createdAt: new Date(current.end).toISOString(),
          duration: current.end - next.start,
        });
      }
    }
  }

  return paddedRecordings;
}
