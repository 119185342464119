import { HorizontalArrowsIcon } from '@va/icons';
import { ReportBlockBody } from '@va/ui/components/report-block';
import { GrayGradientDivider } from '@va/ui/design-system';
import { useWindowDimensions } from '@va/util/hooks';
import React, { useEffect, useRef, useState } from 'react';

type SplitPanelProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  initialLeftWidth?: number;
};

export default function SplitPanel({ left, right, initialLeftWidth = 50 }: SplitPanelProps) {
  const { isTablet } = useWindowDimensions();
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const containerRef = useRef<HTMLDivElement>(null);
  const dividerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const startX = e.pageX;
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const dividerWidth = dividerRef.current?.offsetWidth || 0;
    const startLeftWidth = leftWidth;

    const handleMouseMove = (e: MouseEvent) => {
      const offsetX = e.pageX - startX;
      const newLeftWidth = Math.max(
        0,
        Math.min(100, startLeftWidth + (offsetX / (containerWidth - dividerWidth)) * 100),
      );
      setLeftWidth(newLeftWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (isTablet) {
      setLeftWidth(100);
    } else {
      setLeftWidth(initialLeftWidth);
    }
  }, [isTablet, initialLeftWidth]);

  return (
    <ReportBlockBody className='!p-0'>
      {isTablet ? (
        <div className='flex flex-col'>
          <div className='w-full overflow-hidden'>{left}</div>
          <GrayGradientDivider />
          <div className='overflow-hidden w-full'>{right}</div>
        </div>
      ) : (
        <div ref={containerRef} className='flex items-stretch relative'>
          <div style={{ width: `${leftWidth}%` }} className='overflow-hidden'>
            {left}
          </div>
          <div className='z-20 w-[3px] bg-gray-mercury cursor-ew-resize relative'>
            <div
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-12 h-12 rounded-full bg-white-snow flex items-center justify-center'
              onMouseDown={handleMouseDown}
            >
              <HorizontalArrowsIcon color='#696969' />
            </div>
          </div>
          <div style={{ width: `${100 - leftWidth}%` }} className='overflow-hidden'>
            {right}
          </div>
        </div>
      )}
    </ReportBlockBody>
  );
}
