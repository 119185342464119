import { moduleNames, pathName } from '@va/constants';
import { isModuleEnabled } from '@va/dashboard/selectors/app';
import { showTranslationsLoader } from '@va/dashboard/selectors/ui';
import { getAccessToken, getLastWebsiteId, isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { Redirect } from '@va/standalone/shared/router';
import { LoaderBar } from '@va/ui/design-system';
import { isEcomModuleEnabled, isGAImportEnabled, isOutgoingTrafficFeatureEnabled } from '@va/util/helpers';
import { SsrOnboarding } from '@va/wix/shared/components';
import { Path } from 'history';
import { ComponentType, lazy, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import Layout from 'standalone/components/layout/Layout';
import { ABEDetailsTab } from '../../../../../libs/dashboard/modules/event-tracking/alarming-behavior/src/lib/ABEDetailsTab';
import MyAccount from '../components/AccountSettings/MyAccount';
import StandaloneModals from '../components/modals/StandaloneModals';

export type LazyLoadedComponent = { default: ComponentType<any> };

const ToolsPage = lazy(async () => (await import('@va/dashboard/tabs/tools')) as LazyLoadedComponent);
const ModulesSettingsPage = lazy(
  async () => (await import('@va/dashboard/tabs/module-settings')) as LazyLoadedComponent,
);
const Visitors = lazy(async () => (await import('@va/dashboard/tabs/visitors')) as LazyLoadedComponent);
const PagesTab = lazy(async () => (await import('@va/dashboard/tabs/pages')) as LazyLoadedComponent);
const CampaignsTab = lazy(async () => (await import('@va/dashboard/tabs/campaigns')) as LazyLoadedComponent);

const WeeblyOnboarding = lazy(() =>
  import('@va/standalone/core/tabs/onboarding').then(
    (module): LazyLoadedComponent => ({ default: module.WeeblyOnboarding }),
  ),
);
const StandaloneOnboardingContainer = lazy(() =>
  import('@va/standalone/core/tabs/onboarding').then(
    (module): LazyLoadedComponent => ({ default: module.StandaloneOnboarding }),
  ),
);

const OverviewTab = lazy(async () => (await import('@va/dashboard/tabs/overview')) as LazyLoadedComponent);
const RedirectToOverview = lazy(
  async () => (await import('@va/dashboard/tabs/overview/redirect')) as LazyLoadedComponent,
);

const GeneralSettingsTab = lazy(
  async () => (await import('standalone/components/tabs/general-settings/GeneralSettingsTab')) as LazyLoadedComponent,
);

const UpgradePlanPage = lazy(
  async () => (await import('@va/standalone/core/tabs/upgrade-plan')) as LazyLoadedComponent,
);

const SubscriptionSettingsTabV2 = lazy(
  async () => (await import('@va/dashboard/tabs/subscription-settings')) as LazyLoadedComponent,
);
const Invoice = lazy(async () => (await import('standalone/components/Invoice')) as LazyLoadedComponent);

const GenerateImpersonate = lazy(
  async () => (await import('standalone/components/UnauthenticatedViews/GenerateImpersonate')) as LazyLoadedComponent,
);
const ViewImpersonate = lazy(
  async () => (await import('standalone/components/UnauthenticatedViews/ViewImpersonate')) as LazyLoadedComponent,
);
const LoginContainer = lazy(
  async () => (await import('standalone/components/UnauthenticatedViews/Login')) as LazyLoadedComponent,
);
const AuthExternal = lazy(
  async () => (await import('standalone/components/UnauthenticatedViews/AuthExternal')) as LazyLoadedComponent,
);
const RedirectGoogleViews = lazy(
  async () => (await import('standalone/components/UnauthenticatedViews/RedirectGoogleViews')) as LazyLoadedComponent,
);
const ForgotPassword = lazy(
  async () => (await import('standalone/components/UnauthenticatedViews/ForgotPassword')) as LazyLoadedComponent,
);
const ResetPassword = lazy(
  async () =>
    (await import(
      'standalone/components/UnauthenticatedViews/ResetPassword/ResetPasswordContainer'
    )) as LazyLoadedComponent,
);
const ContributorsLoginScreen = lazy(
  () => import('standalone/components/UnauthenticatedViews/RegisterContributor/ContributorsLoginScreen'),
);
const RegisterPageV2 = lazy(async () => (await import('@va/standalone/core/tabs/register')) as LazyLoadedComponent);

const DeleteAccountContainer = lazy(
  () => import('standalone/components/UnauthenticatedViews/DeleteAccount/DeleteAccount'),
);

const ConfirmNotificationSettingsEmail = lazy(
  () =>
    import(
      'standalone/components/UnauthenticatedViews/ConfirmNotificationSettingsEmail/ConfirmNotificationSettingsEmail'
    ),
);

const ConfirmErrorAlertsSettingsEmail = lazy(
  () =>
    import(
      'standalone/components/UnauthenticatedViews/ConfirmErrorAlertsSettingsEmail/ConfirmErrorAlertsSettingsEmail'
    ),
);

const ConfirmEmail = lazy(
  async () =>
    (await import('standalone/components/UnauthenticatedViews/ConfirmEmail/ConfirmEmail')) as LazyLoadedComponent,
);
const MigrateUser = lazy(
  async () =>
    (await import('standalone/components/UnauthenticatedViews/MigrateUser/MigrateUser')) as LazyLoadedComponent,
);
const AppSumoLandingpage = lazy(async () => (await import('standalone/components/AppSumo')) as LazyLoadedComponent);
const AuthorizeWeebly = lazy(
  async () => (await import('standalone/components/AuthorizeWeebly')) as LazyLoadedComponent,
);

const UnsupportedLocalStorage = lazy(
  async () => (await import('@va/dashboard/tabs/unsupported-local-storage')) as LazyLoadedComponent,
);
//TODO: make an IgnoreVisitsPage for standalone.
//import IgnoreVisitsPage from 'common/components/pages/IgnoreVisits';
const GeneralErrorPage = lazy(async () => (await import('@va/dashboard/tabs/error')) as LazyLoadedComponent);
const UnsubscribePage = lazy(
  async () => (await import('standalone/components/pages/UnsubscribePageWrapper')) as LazyLoadedComponent,
);

const LatestVisitorsOverviewV2 = lazy(
  async () => (await import('@va/dashboard/tabs/visitors/overview')) as LazyLoadedComponent,
);
const VisitorsHistoryV2 = lazy(
  async () => (await import('@va/dashboard/tabs/visitors/history')) as LazyLoadedComponent,
);

const TrafficDetailsPage = lazy(async () => (await import('@va/dashboard/tabs/traffic')) as LazyLoadedComponent);

const OutgoingTrafficTab = lazy(
  async () => (await import('@va/dashboard/tabs/outgoing-traffic')) as LazyLoadedComponent,
);

const DevicesTab = lazy(async () => (await import('@va/dashboard/tabs/devices')) as LazyLoadedComponent);

const WebsiteErrorsTab = lazy(
  async () => (await import('@va/dashboard/tabs/website-errors/main')) as LazyLoadedComponent,
);

const MyCampaignDetails = lazy(
  async () => (await import('@va/dashboard/tabs/campaigns/details')) as LazyLoadedComponent,
);

/** EVENT TRACKING */
const EventTrackingModule = lazy(
  async () => (await import('@va/dashboard/tabs/event-tracking/module')) as LazyLoadedComponent,
);
/** EVENT TRACKING */

const RecordingsDetailsPageV2 = lazy(
  async () => (await import('@va/dashboard/tabs/recordings/details')) as LazyLoadedComponent,
);
const VisitorRecordingsSettingsOnboarding = lazy(
  async () => (await import('@va/dashboard/tabs/recordings/onboardings')) as LazyLoadedComponent,
);

const PageSettingsTab = lazy(async () => (await import('@va/dashboard/tabs/page-settings')) as LazyLoadedComponent);
const PrivacyCenterTab = lazy(async () => (await import('@va/dashboard/tabs/privacy-center')) as LazyLoadedComponent);
const ManageContributors = lazy(
  async () =>
    (await import('standalone/components/tabs/manage-contributors/ManageContributors')) as LazyLoadedComponent,
);
const TrackingCodeTab = lazy(async () => (await import('@va/dashboard/tabs/tracking-code')) as LazyLoadedComponent);

const VisitorRecordingsTabV2 = lazy(async () => (await import('@va/dashboard/tabs/recordings')) as LazyLoadedComponent);

/** FUNNELS */
const CreateFunnelPage = lazy(async () => (await import('@va/dashboard/tabs/funnels/create')) as LazyLoadedComponent);
const EditFunnelPage = lazy(async () => (await import('@va/dashboard/tabs/funnels/edit')) as LazyLoadedComponent);
const FunnelsTab = lazy(async () => (await import('@va/dashboard/tabs/funnels/main')) as LazyLoadedComponent);
const FunnelDetailsPage = lazy(async () => (await import('@va/dashboard/tabs/funnels/details')) as LazyLoadedComponent);
/** FUNNELS */

/** HEATMAPS */
const HeatmapDetailView = lazy(
  async () => (await import('@va/dashboard/tabs/heatmaps/details')) as LazyLoadedComponent,
);
const HeatmapsTab = lazy(async () => (await import('@va/dashboard/tabs/heatmaps')) as LazyLoadedComponent);
const CreateHeatmapPage = lazy(async () => (await import('@va/dashboard/tabs/heatmaps/create')) as LazyLoadedComponent);
/** HEATMAPS */

const ExportTab = lazy(async () => (await import('@va/dashboard/tabs/export')) as LazyLoadedComponent);
const ImportTab = lazy(
  async () => (await import('standalone/components/tabs/import-tab/ImportTab')) as LazyLoadedComponent,
);
const ImportTabV2 = lazy(
  async () => (await import('standalone/components/tabs/import-tab-v2/ImportTab')) as LazyLoadedComponent,
);
const CompetitionTab = lazy(async () => (await import('@va/dashboard/tabs/competition')) as LazyLoadedComponent);

/**** POLLS ****/
const PollsTab = lazy(async () => (await import('@va/dashboard/tabs/feedback/polls/main')) as LazyLoadedComponent);
const CreatePollPage = lazy(
  async () => (await import('@va/dashboard/tabs/feedback/polls/create')) as LazyLoadedComponent,
);
const EditPollPage = lazy(async () => (await import('@va/dashboard/tabs/feedback/polls/edit')) as LazyLoadedComponent);
const PollDetailsPage = lazy(
  async () => (await import('@va/dashboard/tabs/feedback/polls/details')) as LazyLoadedComponent,
);
const PollParticipantsResponsesPage = lazy(
  async () => (await import('@va/dashboard/tabs/feedback/polls/participants')) as LazyLoadedComponent,
);
/**** POLLS ****/

/**** SURVEYS ****/
const SurveysTab = lazy(async () => (await import('@va/dashboard/tabs/feedback/surveys/main')) as LazyLoadedComponent);
const CreateSurveyPage = lazy(
  async () => (await import('@va/dashboard/tabs/feedback/surveys/create')) as LazyLoadedComponent,
);
const EditSurveyPage = lazy(
  async () => (await import('@va/dashboard/tabs/feedback/surveys/edit')) as LazyLoadedComponent,
);
const SurveyDetailsPage = lazy(
  async () => (await import('@va/dashboard/tabs/feedback/surveys/details')) as LazyLoadedComponent,
);
/**** SURVEYS ****/

const VisitorSegments = lazy(
  async () => (await import('@va/dashboard/feature/visitor-segments')) as LazyLoadedComponent,
);
/**** E-COM ****/
const EcomOverview = lazy(async () => (await import('@va/dashboard/tabs/ecom/overview')) as LazyLoadedComponent);
const EcomCustomers = lazy(async () => (await import('@va/dashboard/tabs/ecom/customers')) as LazyLoadedComponent);
const EcomOrders = lazy(async () => (await import('@va/dashboard/tabs/ecom/orders')) as LazyLoadedComponent);
const EcomSalesCharts = lazy(async () => (await import('@va/dashboard/tabs/ecom/sales-charts')) as LazyLoadedComponent);
/**** E-COM ****/

const WebsiteRoute = ({
  component: Component,
  hasBackButton,
  extraProps,
  moduleName,
  ...rest
}: {
  component: any;
  hasBackButton?: boolean;
  extraProps?: Record<string, unknown>;
  exact?: boolean;
  moduleName?: string;
  path: Path | Path[];
}) => {
  const token = getAccessToken();
  const isRefreshInProgress = !!window.refreshTokenPromise;
  const isEnabled = useSelector(isModuleEnabled);
  const history = useHistory();

  if (!isEnabled(moduleName)) {
    history.push('/');
    return;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        token || isRefreshInProgress ? (
          <Layout hasBackButton={hasBackButton}>
            <Suspense fallback={<LoaderBar />}>
              {/* TODO Razvan move these somewhere else */}
              <StandaloneModals />
              <Component {...props} {...extraProps} />
            </Suspense>
          </Layout>
        ) : (
          <Redirect push to='/login' keepQueryParams extraQueryParams={{ pushTo: window.location.pathname }} />
        )
      }
    />
  );
};

const AppRoutes = () => {
  const shouldShowTranslationsLoader = useSelector(showTranslationsLoader);

  const EventTrackingModuleMemoized = useMemo(
    () => SsrOnboarding.bind(this, { children: <EventTrackingModule /> }),
    [],
  );

  if (shouldShowTranslationsLoader) return <LoaderBar />;

  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        {/* Admin routes */}
        <Route path='/generate-impersonate' component={GenerateImpersonate} />
        <Route
          path='/impersonate'
          render={(props) => (
            <ViewImpersonate
              impersonateData={
                props.location.state &&
                (props.location.state as { impersonateData: Record<string, unknown> }).impersonateData
              }
            />
          )}
        />
        {/* Admin routes */}
        {/* @deprecated use //website/:websiteId/unsubscribe after api is deployed and delete this route */}
        <Route
          path='/unsubscribe'
          render={() => {
            const token = getAccessToken();
            const isRefreshInProgress = !!window.refreshTokenPromise;

            return token || isRefreshInProgress ? (
              <UnsubscribePage />
            ) : (
              <Redirect push to={'/login'} keepQueryParams extraQueryParams={{ pushTo: '/unsubscribe' }} />
            );
          }}
        />
        <Route
          path='/website/:websiteId/unsubscribe'
          exact
          render={(props) => {
            const token = getAccessToken();
            const isRefreshInProgress = !!window.refreshTokenPromise;

            return token || isRefreshInProgress ? (
              <UnsubscribePage />
            ) : (
              <Redirect
                push
                to={'/login'}
                keepQueryParams
                extraQueryParams={{ pushTo: `/website/${props.match.params.websiteId}/unsubscribe` }}
              />
            );
          }}
        />
        {/*<Route path='/visit-settings' component={IgnoreVisitsPage}/>*/}
        {/*<Route path='/ignore-visits/:hash' component={IgnoreVisitsPage}/>*/}
        {!isUserLoggedInWithCustomUrl() && <Route exact path={'/register'} component={RegisterPageV2} />}
        {!isUserLoggedInWithCustomUrl() && <Route path='/register/:status?' component={RegisterPageV2} />}

        <Route
          path='/login'
          render={() => {
            const redirectRoute = getLastWebsiteId() ? '/' : pathName.manageWebsites;
            return getAccessToken() ? <Redirect to={redirectRoute} /> : <LoginContainer />;
          }}
        />
        <Route path='/auth/external' component={AuthExternal} />
        <Route path='/analytics/google/views' component={RedirectGoogleViews} />
        {/*/!*Reachable from email*!/*/}
        <Route path='/confirm-email' component={ConfirmNotificationSettingsEmail} />
        <Route path='/confirm-abe-email' component={ConfirmErrorAlertsSettingsEmail} />

        <Route path='/activate' component={StandaloneOnboardingContainer} />
        <Route path='/password/forgot' component={ForgotPassword} />
        <Route path='/password/reset' component={ResetPassword} />
        <Route path='/delete' component={DeleteAccountContainer} />

        <Route path='/contributor' component={ContributorsLoginScreen} />

        <Route exact path='/update-email/:action(confirm|reset)' component={ConfirmEmail} />

        <Route path='/unsupported-localstorage' component={UnsupportedLocalStorage} />
        <Route path='/migrations/claim' component={MigrateUser} />
        <Route path='/invoice/:invoiceKey' component={Invoice} />

        <WebsiteRoute exact path='/' component={RedirectToOverview} />
        <WebsiteRoute exact path='/website/:websiteKey' component={RedirectToOverview} />
        <WebsiteRoute path='/dashboard' component={OverviewTab} />
        <WebsiteRoute path='/website/:websiteKey/dashboard' component={OverviewTab} />

        {isEcomModuleEnabled() && (
          <WebsiteRoute exact path='/website/:websiteKey/ecommerce/overview' component={EcomOverview} />
        )}
        {isEcomModuleEnabled() && (
          <WebsiteRoute exact path='/website/:websiteKey/ecommerce/customers' component={EcomCustomers} />
        )}
        {isEcomModuleEnabled() && (
          <WebsiteRoute exact path='/website/:websiteKey/ecommerce/orders' component={EcomOrders} />
        )}
        {isEcomModuleEnabled() && (
          <WebsiteRoute path='/website/:websiteKey/ecommerce/sales-charts' component={EcomSalesCharts} />
        )}

        <WebsiteRoute
          exact
          path='/website/:websiteKey/statistics/overview'
          moduleName={moduleNames.webStatsOverview}
          component={LatestVisitorsOverviewV2}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/visitors/history/:sessionKey'
          component={VisitorsHistoryV2}
          moduleName={moduleNames.visitorsHistory}
        />

        <WebsiteRoute path='/website/:websiteKey/visitors' component={Visitors} moduleName={moduleNames.visitors} />

        <WebsiteRoute path='/website/:websiteKey/pages' component={PagesTab} moduleName={moduleNames.pages} />

        <WebsiteRoute
          exact
          // campaignKey is a MD5 value (32 hexadecimal digits)
          path='/website/:websiteKey/campaigns/:campaignKey([A-Fa-f0-9]{32})'
          component={MyCampaignDetails}
          moduleName={moduleNames.utmMyCampaigns}
        />

        <WebsiteRoute path='/website/:websiteKey/devices' component={DevicesTab} moduleName={moduleNames.devices} />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/website-errors'
          component={WebsiteErrorsTab}
          moduleName={moduleNames.websiteErrors}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/competition'
          component={CompetitionTab}
          moduleName={moduleNames.competition}
        />
        <WebsiteRoute
          path='/website/:websiteKey/traffic'
          component={TrafficDetailsPage}
          moduleName={moduleNames.trafficStructure}
        />

        {isOutgoingTrafficFeatureEnabled() && (
          <WebsiteRoute
            path='/website/:websiteKey/outgoing-traffic'
            component={OutgoingTrafficTab}
            moduleName={moduleNames.outgoingTraffic}
          />
        )}

        <WebsiteRoute
          path='/website/:websiteKey/campaigns'
          component={CampaignsTab}
          moduleName={moduleNames.utmOverview}
        />
        <WebsiteRoute path='/my-account' component={MyAccount} />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/settings'
          component={GeneralSettingsTab}
          moduleName={moduleNames.websiteSettings}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/settings/subscription'
          component={SubscriptionSettingsTabV2}
          moduleName={moduleNames.subscription}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/settings/pages'
          component={PageSettingsTab}
          moduleName={moduleNames.specialPages}
        />

        <WebsiteRoute
          path='/website/:websiteKey/settings/privacy-center'
          component={PrivacyCenterTab}
          moduleName={moduleNames.privacyCenter}
        />

        <WebsiteRoute
          path='/website/:websiteKey/settings/visitor-segments'
          component={VisitorSegments}
          hasBackButton
          moduleName={moduleNames.segments}
        />

        <WebsiteRoute
          path='/website/:websiteKey/settings/modules'
          component={SsrOnboarding.bind(this, { children: <ModulesSettingsPage /> })}
          moduleName={moduleNames.moduleSettings}
        />

        <WebsiteRoute
          path='/website/:websiteKey/settings/manage-contributors'
          component={ManageContributors}
          moduleName={moduleNames.manageContributors}
        />
        <WebsiteRoute
          exact
          path='/settings/tracking-code'
          component={TrackingCodeTab}
          moduleName={moduleNames.trackingCode}
        />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/settings/tracking-code'
          component={TrackingCodeTab}
          moduleName={moduleNames.trackingCode}
        />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/settings/import'
          component={isGAImportEnabled() ? ImportTabV2 : ImportTab}
          moduleName={moduleNames.import}
        />
        <WebsiteRoute path='/website/:websiteKey/tools' component={ToolsPage} moduleName={moduleNames.tools} />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/recordings-onboarding'
          component={SsrOnboarding.bind(this, { children: <VisitorRecordingsSettingsOnboarding /> })}
          moduleName={moduleNames.sessionRecordings}
        />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/recordings'
          component={SsrOnboarding.bind(this, { children: <VisitorRecordingsTabV2 /> })}
          moduleName={moduleNames.sessionRecordings}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/event-tracking/alarming-behavior-events/:trigger'
          component={ABEDetailsTab}
          moduleName={moduleNames.eventTracking}
        />

        <WebsiteRoute
          path='/website/:websiteKey/behaviour/event-tracking'
          component={EventTrackingModuleMemoized}
          moduleName={moduleNames.eventTracking}
        />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/funnels/create'
          component={CreateFunnelPage}
          moduleName={moduleNames.funnels}
        />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/funnels/:funnelId([A-Za-z0-9]{14}.[0-9]{8})'
          component={FunnelDetailsPage}
          hasBackButton
          moduleName={moduleNames.funnels}
        />
        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/funnels/edit/:funnelId'
          component={EditFunnelPage}
          moduleName={moduleNames.funnels}
        />
        <WebsiteRoute
          path='/website/:websiteKey/behaviour/funnels'
          component={FunnelsTab}
          moduleName={moduleNames.funnels}
        />

        <WebsiteRoute
          // 24-character hexadecimal string value
          path='/website/:websiteKey/behaviour/heatmaps/:heatmapId([A-Fa-f0-9]{24})'
          component={HeatmapDetailView}
          hasBackButton
          moduleName={moduleNames.heatmaps}
        />
        <WebsiteRoute
          path='/website/:websiteKey/behaviour/heatmaps/create'
          component={SsrOnboarding.bind(this, { children: <CreateHeatmapPage /> })}
          moduleName={moduleNames.heatmaps}
        />
        <WebsiteRoute
          path={'/website/:websiteKey/behaviour/heatmaps'}
          component={SsrOnboarding.bind(this, { children: <HeatmapsTab /> })}
          moduleName={moduleNames.heatmaps}
        />

        {/* Feedback/Polls */}
        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/polls'
          component={SsrOnboarding.bind(this, { children: <PollsTab /> })}
          moduleName={moduleNames.polls}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/polls/create'
          component={SsrOnboarding.bind(this, { children: <CreatePollPage poll={undefined} /> })}
          hasBackButton
          moduleName={moduleNames.polls}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/polls/:id/edit'
          component={EditPollPage}
          moduleName={moduleNames.polls}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/polls/:id/visitors/:visitorKey/responses'
          component={PollParticipantsResponsesPage}
          moduleName={moduleNames.polls}
        />

        <WebsiteRoute
          path='/website/:websiteKey/feedback/polls/:id'
          component={PollDetailsPage}
          moduleName={moduleNames.polls}
        />

        {/* Feedback/Surveys */}
        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/surveys'
          component={SurveysTab}
          moduleName={moduleNames.surveys}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/surveys/create'
          component={CreateSurveyPage}
          hasBackButton
          moduleName={moduleNames.surveys}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/feedback/surveys/:id/edit'
          component={EditSurveyPage}
          moduleName={moduleNames.surveys}
        />
        <WebsiteRoute
          path='/website/:websiteKey/feedback/surveys/:id'
          component={SurveyDetailsPage}
          hasBackButton
          moduleName={moduleNames.surveys}
        />

        <WebsiteRoute
          exact
          path='/website/:websiteKey/behaviour/recordings/:sessionId'
          component={RecordingsDetailsPageV2}
          moduleName={moduleNames.sessionRecordings}
        />

        <WebsiteRoute path='/website/:websiteKey/export' component={ExportTab} moduleName={moduleNames.export} />

        <WebsiteRoute
          path={['/website/:websiteKey/upgrade', '/upgrade']}
          component={UpgradePlanPage}
          moduleName={moduleNames.upgrade}
        />

        <Route exact path='/appsumo' component={AppSumoLandingpage} />
        <Route exact path='/authorize-weebly' component={AuthorizeWeebly} />
        <Route exact path='/weebly-onboarding' component={WeeblyOnboarding} />
        <Route component={GeneralErrorPage} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
