import { RadioButton } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { ButtonLoader } from '@va/svg-visa-icons';
import { addNumberSeparator, getNumberWithArithmeticOperator, getPercentage } from '@va/util/helpers';
import classNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const Overview = ({ websiteDetail, inProgress, setSelectedOption, selectedOption, currentCount, previousCount }) => {
  const translate = useTranslate();
  const percentage = getPercentage(currentCount, previousCount);

  const handleOnClick = useCallback(
    (option) => {
      setSelectedOption(option);
    },
    [setSelectedOption],
  );

  const option = [
    {
      label: translate('card.visitors.title'),
      count: websiteDetail.get('totalVisitors'),
      value: 1,
    },
    {
      label: translate('card.uniqueVisitors.title'),
      count: websiteDetail.get('totalNewVisitors'),
      value: 2,
    },
    {
      label: translate('card.pageVisits.title'),
      count: websiteDetail.get('totalPageVisits'),
      value: 3,
    },
    {
      label: translate('card.conversions.title'),
      count: websiteDetail.get('totalConversions'),
      value: 4,
    },
  ];

  return (
    <div className='overview-wrapper flex flex-col flex-nowrap sm:flex-row sm:flex-wrap'>
      {option.map((item) => {
        const { label, count, value } = item;
        return (
          <OverviewItem
            label={label}
            count={count}
            value={value}
            selectedOption={selectedOption}
            onClick={() => handleOnClick(value)}
            percentage={percentage}
            inProgress={inProgress}
          />
        );
      })}
    </div>
  );
};

export default Overview;

Overview.propTypes = {
  websiteDetail: PropTypes.instanceOf(Immutable.Map).isRequired,
  inProgress: PropTypes.bool.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.number.isRequired,
  currentCount: PropTypes.number.isRequired,
  previousCount: PropTypes.number.isRequired,
};

const OverviewItem = ({ label, count = 0, value, selectedOption, onClick, percentage = 0, inProgress }) => {
  const { locale } = useLocale();
  const isSelected = value === selectedOption;

  function getPercentageContent() {
    if (!isSelected) return;
    /* Todo remove this isSelected condition after modification in visitors-summary api/after new api */

    return (
      <div
        className={classNames(
          'flex items-end font-semibold text-12 leading-24 h-full',
          { 'text-green-green': percentage > 0 },
          { 'text-red': percentage < 0 },
        )}
      >
        {inProgress ? (
          <ButtonLoader color={window.PRIMARY_COLOR} />
        ) : (
          `${isNaN(percentage) ? 0 : getNumberWithArithmeticOperator(percentage)}%`
        )}
      </div>
    );
  }

  return (
    <div className='py-1 flex justify-between items-end' style={{ flex: '1 0 50%' }}>
      <div className='flex items-center'>
        <div className={classNames('flex items-center mr-4 cursor-pointer sm:hidden')} onClick={onClick}>
          <RadioButton isSelected={isSelected} />
        </div>
        <div>
          <div className='text-12 font-medium leading-18 text-gray-dusty'>{label}</div>
          <div className='font-semibold text-15 leading-24 flex'>
            {addNumberSeparator(count, locale)} <div className='ml-4 hidden mobile:block'>{getPercentageContent()}</div>
          </div>
        </div>
      </div>
      <span className='block mobile:hidden'>{getPercentageContent()}</span>
    </div>
  );
};

OverviewItem.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  selectedOption: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  percentage: PropTypes.func.isRequired,
};
